<template>
    <div class="bg-image d-flex align-items-center" id="id01" style="background-image: url('assets/images/bgs.jpg');">
        <div class="container d-flex justify-content-center align-items-center">
        <div class="card align-self-center" style="width: 23rem;">
            <img src="assets/images/1234.png" class="card-img-top img-fluid px-3 py-2" alt="Logo Spf" style="background-color: #0C213A;">
            <div class="card-body">
            <form class="card-body pb-0" v-on:submit.prevent="setLogin" autocomplete="off">
                <div class="mb-3">
                <label class="text-black-50 h6">Username</label>
                <input class="form-control rounded-0 py-2 mb-3" v-model="rec.username" id="username" placeholder="Enter Username or Email" type="text" ref="email" required>
                </div>
                <div class="mb-3">
                <label class="text-black-50 h6">Password</label>
                <div class="input-group">
                    <input class="form-control rounded-0" v-model="rec.password" placeholder="Enter Password" :type="lihat ? 'text' : 'password'" required>
                    <span v-on:click="lihat = !lihat" type="button" class="input-group-text rounded-0" style="">
                    <i class="fa fa-eye"></i>
                    </span>
                </div>
                </div>
                <p class="text-black-50 h6">Captcha</p>
                <div class="d-flex mb-3">
                <div class="col-md-4" v-on:click="loadCapcha">
                    <input type="text" class="form-control btn btn-primary py-2 rounded-0" id="mainCaptcha" v-model="rec.mainCaptcha" disabled="">
                </div>
                <div class="col-md-8">
                    <input class="form-control py-2 rounded-0" name="capcha" v-model="rec.cap" type="text" placeholder="Enter Captcha" required>
                </div>
                </div>
                <div class="mb-3">
                <input class="form-check-input p-2" type="checkbox" checked="checked">
                <label class="form-check-label text-black-50 ms-2">Remember me</label>
                </div>
                <button class="btn btn-primary rounded-0 col-12 py-2 mb-3" type="submit" name="submit">Login &nbsp;<span class="mdi mdi-login-variant"></span></button>
                <p class="text-black-50 mt-3 text-center">Sukses Perkasa Forestama © 2023</p>
            </form>
            </div>
        </div>
        </div>
    </div>
</template>
<script lang="js">
/* eslint-disable */
// import md5 from  '../../assets/doevent/md5.js';
import config from "@/conf/Config.js";
import axios from "axios";
axios.defaults.baseURL = config.apiUrl;
axios.defaults.headers.common["Authorization"] = localStorage.tsalestoken;


export default {
    head: {
        title: {
            inner: "Login",
        },
    },
    name: "Login",
    data() {
        return {
            lihat : false,
            screenHeight: 0,
            cap: {
                image: "",
                image_url: "",
            },
            rec: {
                username: "",
                password: "",
                cap: "",
                ingat: "T",
                mainCaptcha: "",
                pfunction: "setlogin"
            },
            recscounter: [],
            filters: {
                operator: "AND",
                field: [
                    {
                        key: "username",
                        type: "text",
                        name: "username",
                        filter: true,
                        width: "30px",
                        grid: true,
                    },
                ],
                find: [],
            },
        };
    },
    methods: {
        removeSpaces(string){
            return string.split(' ').join('');
        },
        async loadCapcha(){
            const obj = await axios({
                method: "post",
                url: "admin/ApiToken.php",
                data: {
                    pfunction : "getcaptcha"
                },
            });
            if (obj.data.captcha === undefined) {
                this.rec.mainCaptcha = obj.data.msg;
            }else{
                this.rec.mainCaptcha = obj.data.captcha;
            }
        },
        setLogin() {
            let self = this;
            if (self.rec.mainCaptcha != self.rec.cap || self.rec.cap == ""){
                // self.loadCapcha();
                self.$toast.error('Captcha Invalid');
                return false;
            }else{
                delete axios.defaults.headers.common["Authorization"];
                axios.defaults.headers.common["Authorization"] = localStorage.ctoken;
                axios.post("admin/ApiAdmin.php",self.rec).then(function (response) {
                    if (response.data.success == true) {
                        localStorage.tsalestoken = 'Bearer '+response.data.token;
                        localStorage.username = response.data.user[0]['vc_user'];
                        // localStorage.idsales = response.data.user[0]['vc_id'];
                        localStorage.tsaleslevel = response.data.user[0]['c_userlevel'];
                        // localStorage.username = response.data.user[0]['vc_user'];
                        localStorage.userall = response.data.user[0];
                        localStorage.setItem('userall', JSON.stringify(response.data.user[0]));
                        self.$toast.success('Login sukses');
                        self.$router.push('/home');
                    } else {
                        self.$toast.error(response.data.msg);
                    }
                });
            }
            event.preventDefault();
            return false;
        },
        async loadToken() {
            const obj = await axios({
                method: "post",
                url: "admin/ApiToken.php",
                data: {
                    pfunction : "gettoken"
                },
            });
            localStorage.ctoken = 'Bearer '+obj.data.token;
            this.loadCapcha();
        },
    },
    mounted() {
        this.loadToken();
        this.screenHeight = window.innerHeight;
        document.getElementById("username").focus();
    },
}
</script>

<style>
  .bg-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
  }
</style>